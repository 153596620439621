import React from 'react'

import cssServicios from '../styles/servicios.module.scss'
import Layout from "../components/layout"

const Servicios = () => {
    return (
      <Layout>
        <div className={cssServicios.raiz}>
            <div className={cssServicios.banner}>
                <h1 className={cssServicios.tituloprincipal}>Sirviendo a la Industria Mexicana del Petróleo y Gas</h1>
            </div>

            <div className={cssServicios.oferta}>
                <section className={cssServicios.categoria}>
                    <h2 className={cssServicios.titulo}>Geociencias</h2>
                    <ul>
                        <li>Caracterización estática.</li>
                        <li>Planes iniciales de desarrollo.</li>
                        <li>Modelado petrofísico (física de rocas).</li>
                        <li>Análisis geomecánico (predrill y post-mortem).</li>
                        <li>Adquisición satelital de información de subsuelo (STeP).</li>
                    </ul>
                </section>
                <section className={cssServicios.categoria}>
                    <h2 className={cssServicios.titulo}>Ingeniería de Yacimientos</h2>
                    <ul>
                        <li>Modelado dinámico.</li>
                        <li>Planes de desarrollo para la extracción.</li>
                        <li>Estudios y diseño de pilotos EOR.</li>
                        <li>Servicio de Análisis nodal en pozos de petróleo y gas.</li>
                        <li>Servicio de Diagnóstico Integral en pozos con baja productividad y posibilidades de reactivación.</li>
                        <li>Gerenciamiento de energía de yacimiento (tecnología AWPA).</li>
                    </ul>
                </section>
                <section className={cssServicios.categoria}>
                    <h2 className={cssServicios.titulo}>Perforación y Terminación</h2>
                    <ul>
                        <li>Diseño de programas de perforación y RMA/RME.</li>
                        <li>Estudio de pozos bajo metodología VCDSE.</li>
                        <li>Monitoreo y seguimiento en tiempo real de perforación.</li>
                        <li>Análisis geomecánico (Predrill y Post-mortem).</li>
                    </ul>
                </section>
                <section className={cssServicios.categoria}>
                    <h2 className={cssServicios.titulo}>Productividad de Pozos</h2>
                    <ul>
                        <li>Diagnóstico integral en pozos con baja productividad y posibilidades de reactivación.</li>
                        <li>Análisis nodal de pozos.</li>
                        <li>Análisis e interpretación de registros PLT.</li>
                    </ul>
                </section>
                <section className={cssServicios.categoria}>
                    <h2 className={cssServicios.titulo}>Evaluación Económica</h2>
                    <ul>
                        <li>Evaluación económica de proyectos.</li>
                        <li>Análisis de riesgo/incertidumbre.</li>
                        <li>CAPEX/OPEX.</li>
                        <li>Análisis Causa Raíz (ACR) para pozos.</li>
                        <li>Modelo integrado de yacimientos.</li>
                        <li>Evaluación de modelos de negocio.</li>
                        <li>Aseguramiento de la calidad de proyectos.</li>
                    </ul>
                </section>
            </div>
        </div>
      </Layout>
    )
}

export default Servicios;
